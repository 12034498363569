import React from 'react';
import {
  ListItem,
  ListItemDecorator,
  ListItemContent,
  IconButton,
  Typography,
  List
} from '@mui/joy';
import {
  SelfImprovement,
  Face,
  SpatialAudioOff,
  ContentCopy
} from '@mui/icons-material';
import { getSpeech } from '../modules/firebase';
import Markdown from 'react-markdown';
import sound from '../modules/click.json';

const MessageHistory = ({
  messages,
  user,
  isFetchingSpeech,
  setIsFetchingSpeech,
  setSnackbarMessage,
  setSpeech
}) => {
  if (messages.length === 1) return null;

  const formattedMessages = [];

  for (const [i, message] of messages.entries()) {
    if (message.role === 'assistant') {
      formattedMessages.push(
        <ListItem key={`assistant-${i}`}>
          <ListItemDecorator>
            <SelfImprovement />
          </ListItemDecorator>
          <ListItemContent>
            <Typography level="title-sm">Collective Council</Typography>
          </ListItemContent>
        </ListItem>,
        <ListItem key={`assistantResponse-${i}`}>
          <ListItemDecorator></ListItemDecorator>
          <ListItemContent>
            <Markdown children={message.content} />
            <IconButton
              variant="plain"
              size="sm"
              disabled={isFetchingSpeech}
              onClick={async () => {
                setIsFetchingSpeech(true);
                let voiceChat = new Audio();
                voiceChat.autoplay = true;
                voiceChat.src = `data:audio/wav;base64,${sound.audio}`;

                const base64Audio = await getSpeech({
                  text: message.content,
                  voice: user.voice
                });
                setIsFetchingSpeech(false);
                voiceChat.src = `data:audio/mp3;base64,${base64Audio}`;
                setSpeech(voiceChat);
              }}
            >
              <SpatialAudioOff />
            </IconButton>
            <IconButton
              variant="plain"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(message.content);
                setSnackbarMessage('Text copied.');
              }}
            >
              <ContentCopy />
            </IconButton>
          </ListItemContent>
        </ListItem>
      );
    } else if (message.role === 'user') {
      formattedMessages.push(
        <ListItem key={`you-${i}`}>
          <ListItemDecorator>
            <Face />
          </ListItemDecorator>
          <ListItemContent>
            <Typography level="title-sm">{user?.name}</Typography>
          </ListItemContent>
        </ListItem>,
        <ListItem key={`message-${i}`}>
          <ListItemDecorator></ListItemDecorator>
          <ListItemContent sx={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
            {message.content[0]?.type === 'image' ? (
              <img
                key={`message-${i}`}
                src={`data:${message.content[0].source.media_type};base64,${message.content[0].source.data}`}
                alt="Uploaded content"
                style={{ maxWidth: '100%' }}
              />
            ) : (
              message.content
            )}
          </ListItemContent>
        </ListItem>
      );
    }
  }

  return <List>{formattedMessages}</List>;
};

export default MessageHistory;
